import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    firstSection: {
        background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark} 150%)`,
        textAlign: 'left',
        padding: '10px 20px 0',
        '& .slogan': {
            display: 'block',
            color: theme.palette.primary.contrastText,
            fontSize: '50px',
            textTransform: 'uppercase',
            fontWeight: '700',
            lineHeight: 1.2
        },
        '& button': {
            marginTop: '30px',
            borderRadius: '50px'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '900px',
            '& .slogan-container': {
                padding: '50px',
                zIndex: '1',
                '& .slogan': {
                    fontSize: '100px',
                }
            },
            '& .image-container': {
                display: 'flex',
                flexDirection: 'column-reverse',
                '& img': {
                    width: '100%',
                    maxWidth: '560px',
                    display: 'block',
                }
            }
        }
    },
    secondSection: {
        padding: '100px 20px',
        '& .grid-item-container': {
            marginTop: '15px',
            '& img': {
                maxHeight: '200px',
                maxWidth: '40%'
            },
            '& h1': {
                display: 'block',
                color: theme.palette.primary.dark,
                fontSize: '50px',
                margin: '0'
            },
            '& p': {
                width: '50%',
                margin: 'auto',
                color: theme.palette.primary.dark,
            }
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '900px',
        }
    },
    thirdSection: {
        background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark} 150%)`,
        padding: '10px 20px',
        '& .text-container': {
            textAlign: 'left',
            '& h1, & p': {
                color: theme.palette.primary.contrastText
            },
            '& h1': {
                fontSize: '30px'
            },
            '& p': {
                '&:first-of-type': {
                    color: 'unset'
                }
            }
        },
        '& .image-container': {
            '& img': {
                width: '100%',
            }
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '900px',
            minHeight: '600px',
            padding: '50px',
            '& .text-container': {
                '& h1': {
                    fontSize: '60px !important',
                    marginTop: '0'
                }
            },
            '& .image-container': {
                alignSelf: 'flex-end'
            }
        }
        // textAlign: 'left',
        // padding: '100px',

    },
    fourthSection: {
        padding: '10px 20px',
        textAlign: 'left',
        '& h1': {
            fontSize: '30px'
        },
        '& img': {
            maxWidth: '100%'
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: '900px',
            padding: '50px',
            textAlign: 'right',
            minHeight: '600px',
            '& h1': {
                fontSize: '60px'
            },
            '& .image-container': {
                alignSelf: 'flex-end',
                '& img': {
                    maxHeight: '350px',
                }
            }
        }
    }
}))