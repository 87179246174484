import logo from './logo.svg';
import './App.css';
import './components/navbar/navbar'
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Home from './screens/home/home';
import { CssBaseline } from '@material-ui/core';

function App() {
  return (
    <>
    <CssBaseline/>
    <div className="App">
      <Navbar></Navbar>
      <Home></Home>
      <Footer></Footer>
    </div>
    </>
  );
}

export default App;
