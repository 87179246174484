import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useStyles } from "./navbarStyles";
import Grid from "@material-ui/core/Grid";
import Logo from "../../assets/logos/Logo1.svg";
import { Divider, Drawer, Hidden, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import MenuIcon from '../../assets/navbar/sidebar_icon.svg';

export default function Navbar() {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navBarContent = (rootClass) => (
    <List component='nav' className={rootClass}>
      <ListItem className={classes.consulenceButton} button component='a' href='#gd-footer' onClick={() => setMenuOpen(false)}>
        <ListItemText>
          Require a consulence
        </ListItemText>
      </ListItem>
      <ListItem button component='a' href='#whoWeAre-section' onClick={() => setMenuOpen(false)}>
        <ListItemText>
          Who we are
        </ListItemText>
      </ListItem>
      <ListItem button component='a' href='#ourVision-section' onClick={() => setMenuOpen(false)}>
        <ListItemText>
          Our vision
        </ListItemText>
      </ListItem>
      <ListItem button component='a' href='#ourCulture-section' onClick={() => setMenuOpen(false)}>
        <ListItemText>
          Our culture
        </ListItemText>
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="relative" color="white">
        <Toolbar className={classes.navBar}>
          <img src={Logo} className='logo' alt='growth developing logo' />
          <Hidden mdUp>
            <IconButton className='menu-button' edge='end' onClick={toggleMenu}>
              <img src={MenuIcon} alt='side menu icon' />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {navBarContent(classes.desktopMenu)}
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          anchor='right'
          open={menuOpen}
          onClose={toggleMenu}
          onRequestChange={(open) => { console.log("CIAO - " + open) }}
          ModalProps={{
            keepMounted: true
          }}>
          {navBarContent(classes.sideMenu)}
        </Drawer>
      </Hidden>
    </>
  );
}