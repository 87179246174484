import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    footerGroup: {
        background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark} 150%)`,
        paddingBottom: '20px'
    },
    contactUsForm: {
        marginTop: '4vh',
        '& .MuiGrid-item': {
            margin: '5px',
        },
        '& button': {
            borderRadius: '50px',
            width: '100%',
            '& > span': {
                color: theme.palette.primary.contrastText,
            }
        }
        // [theme.breakpoints.up('md')]: {
        // }
    },
    contactUsInput: {
        background: "#ffffff",
        borderRadius: "50px",
        padding: '5px 15px',
        width: '100%',
        '&.Mui-error': {
            border: '3px solid ' + theme.palette.error.main,
            padding: '2px 12px',
        }
    },
    footerTitle: {
        fontSize: '52px',
        marginTop: '0',
        marginBottom: '0'
    },
    footerParagraph: {
        marginTop: '8vh',
    },
    gdLogoContainer: {
        paddingLeft: '20px'
    },
    socialsGrid: {
        [theme.breakpoints.up('md')]: {
            paddingRight: '20px',
        },
        '& .social-button': {
            width: '70px',
            height: '66px',
            '& img': {
                width: '54px'
            }
        }
    }
}))