import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useStyles } from "./footerStyle";
import Grid from "@material-ui/core/Grid";
import gd_logo from '../../assets/logos/Logo1.svg'
import facebook_logo from "../../assets/footer/facebook.svg";
import twitter_logo from '../../assets/footer/twitter.svg';
import instagram_logo from '../../assets/footer/instagram.svg';
import linkedin_logo from '../../assets/footer/linkedin.svg';

import { FormControl, Hidden, Input, InputBase } from "@material-ui/core";

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid id='gd-footer' container className={classes.footerGroup}>
      <Grid className={classes.footerParagraph} item xs={12}>
        <h1 className={classes.footerTitle}>Contact us</h1>
        <ContactUsForm></ContactUsForm>
      </Grid>
      <Grid className={classes.footerParagraph} item xs={12}>
        <ThirdSection></ThirdSection>
      </Grid>
    </Grid>
  )

  function ContactUsForm() {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [emailValid, setEmailValid] = useState(null);
    const [nameValid, setNameValid] = useState(null);
    const [formState, setFormState] = useState('');

    const validateEmail = () => {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi;
      return regex.test(email);
    }

    const submitForm = (e) => {
      e.preventDefault();

      const valid = { 'name': name !== '', 'email': validateEmail() };
      setNameValid(valid.name);
      setEmailValid(valid.email);
      if (!valid.name || !valid.email) {
        console.log('please use a valid name and email address');
        return;
      }

      console.log('submitting');

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", name);
      urlencoded.append("email", email);

      const requestOptions = {
        method: 'POST',
        body: urlencoded
      }

      setFormState('pending');
      fetch('http://growthdeveloping.com/functions/emailsender.php', requestOptions).then(res => {
        // console.log(res);
        if (res.status === 200) {
          setFormState('success');
        } else {
          setFormState('failure');
        }
      });
    }

    if (formState === 'success') {
      return (
        <h2>Your request has been sent</h2>
      );
    }
    if (formState === 'failure') {
      return (
        <h2>Your request could not been sent. Please, reload the page and try again</h2>
      );
    }

    return (
      <form className={classes.contactUsForm} onSubmit={submitForm}>
        <Grid container direction='row' justify='center'>
          <Grid item xs={10} md={2}>
            <InputBase
              className={classes.contactUsInput}
              placeholder='Your name'
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (nameValid !== null)
                  setNameValid(null);
              }}
              error={nameValid !== null && !nameValid} />
          </Grid>
          <Grid item xs={10} md={4}>
            <InputBase
              className={classes.contactUsInput}
              placeholder='Your email'
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (emailValid !== null)
                  setEmailValid(null);
              }}
              error={emailValid !== null && !emailValid} />
          </Grid>
          <Grid item xs={10} md={2}>
            <Button type="submit" variant="contained" size='large' color="secondary">Let's innovate</Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  function ThirdSection() {

    const desktopSocialGrid = (
      <>
        <Grid item container xs={12} justify="flex-end">
          <Grid item>
            <Button href="https://facebook.com" className='social-button'>
              <img
                alt="Facebook logo"
                src={facebook_logo} />
            </Button>
          </Grid>
          <Grid item>
            <Button href="https://facebook.com" className='social-button'>
              <img
                alt="Twitter logo"
                src={twitter_logo} />
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Grid item>
            <Button href="https://facebook.com" className='social-button'>
              <img
                alt="Instagram logo"
                src={instagram_logo} />
            </Button>
          </Grid>
          <Grid item>
            <Button href="https://facebook.com" className='social-button'>
              <img
                alt="Linkedin logo"
                src={linkedin_logo} />
            </Button>
          </Grid>
        </Grid>
      </>
    );

    const mobileSocialGrid = (
      <>
        <Grid item xs={3}>
          <Button href="https://facebook.com" className='social-button'>
            <img
              alt="Facebook logo"
              src={facebook_logo} />
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button href="https://facebook.com" className='social-button'>
            <img
              alt="Twitter logo"
              src={twitter_logo} />
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button href="https://facebook.com" className='social-button'>
            <img
              alt="Instagram logo"
              src={instagram_logo} />
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button href="https://facebook.com" className='social-button'>
            <img
              alt="Linkedin logo"
              src={linkedin_logo} />
          </Button>
        </Grid>
      </>
    );

    return (
      <Grid container direction="row" justify="space-between" >
        <Grid item xs={9} md={3} className={classes.gdLogoContainer}>
          <img src={gd_logo} alt="Growth Developing logo" />
        </Grid>
        <Grid item container className={classes.socialsGrid} xs={12} md={2}>
          <Hidden mdUp>
            {mobileSocialGrid}
          </Hidden>
          <Hidden smDown>
            {desktopSocialGrid}
          </Hidden>
        </Grid>
      </Grid>
    );
  }

}