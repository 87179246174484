import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    navBar: {
        '& .logo': {
            maxHeight: '64px'
        },
        '& .menu-button img': {
            width: '47px',
            height: '47px'
        },
        justifyContent: 'space-between'
    },
    desktopMenu: {
        // minWidth: '80%',
        display: 'flex',
        justifyContent: 'end',
        '& > .MuiListItem-root': {
            width: 'unset',
            borderRadius: '10px',

        }
    },
    sideMenu: {
        width: '50vw',

    },
    consulenceButton: {
        backgroundColor: theme.palette.info.main + ' !important',
        color: theme.palette.info.contrastText + ' !important',
        '&:hover': {
            backgroundColor: theme.palette.info.dark + ' !important'
        }
    }
}));