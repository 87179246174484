import React from "react";
import { Box, Button, Container, Grid, Hidden, SvgIcon, useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from "./homeStyles";
import DesignIcon from '../../assets/home/design_illustration.svg';
import DevelopmentIcon from '../../assets/home/development_illustration.svg';
import MarketingIcon from '../../assets/home/marketing_illustration.svg';
import SecurityIcon from '../../assets/home/security_illustration.svg';
import DeskImg from '../../assets/home/desk.jpg';
import BooksImg from '../../assets/home/books.jpg';
import HandImg from '../../assets/home/hand_keeping_iphone.png';

export default function Home() {

    return (
        <Grid container direction='column' wrap='nowrap'>
            <Grid item xs={12}>
                <FirstBlock></FirstBlock>
            </Grid>
            <Grid item xs={12}>
                <SecondBlock></SecondBlock>
            </Grid>
            <Grid item xs={12}>
                <ThirdBlock></ThirdBlock>
            </Grid>
            <Grid item xs={12}>
                <FourthBlock></FourthBlock>
            </Grid>
        </Grid>
    );
}

function FirstBlock() {
    const classes = useStyles();

    return (
        <Grid container id='introduction-section' className={classes.firstSection} direction='row'>
            <Grid item xs={12} md={8} className='slogan-container'>
                <span className="slogan">Fear<br />often precedes<br />enthusiasm<br />of innovation</span>
                <Button variant="contained" size='large' color="secondary" href='#gd-footer'>Let's innovate</Button>
            </Grid>
            <Hidden smDown>
                <Grid item md={4} className='image-container'>
                    <img src={HandImg} alt="hand keeping iPhone" />
                </Grid>
            </Hidden>
        </Grid>
    )
}

function SecondBlock() {
    const classes = useStyles();
    return (
        <Grid container className={classes.secondSection} id='whoWeAre-section'>
            <Grid item xs={12} sm={6} className='grid-item-container'>
                <img src={DesignIcon} alt='design' />
                <h1>Design</h1>
                <p>We use an iterative process for solving problems that puts client at the center</p>
            </Grid>
            <Grid item xs={12} sm={6} className='grid-item-container'>
                <img src={DevelopmentIcon} alt='development' />
                <h1>Development</h1>
                <p>We use the best suitable and innovative technologies to develop our projects</p>
            </Grid>
            <Grid item xs={12} sm={6} className='grid-item-container'>
                <img src={SecurityIcon} alt='security' />
                <h1>Security</h1>
                <p>We have the necessary skills to guarantee the safety, quality and compliance with the standards of the ICT applications market</p>
            </Grid>
            <Grid item xs={12} sm={6} className='grid-item-container'>
                <img src={MarketingIcon} alt='marketing' />
                <h1>Marketing</h1>
                <p>A rapid experimentation process focused on business growth by measuring marketing channels using KPIs</p>
            </Grid>
        </Grid>
    )
}

function ThirdBlock() {
    const classes = useStyles();

    return (
        <Grid container id='ourVision-section' className={classes.thirdSection} direction='row' justify='space-between'>
            <Grid item xs={12} md={6} className='text-container'>
                <h1>Our vision</h1>
                <p>An interdisciplinary team to transform your business</p>
                <p>We work with the philosophy of "continuous improvement":<br />developing our work, measuring results and improving it.</p>
                <p>The whole team has a 360° view of the processes in place.<br />Anyone can propose improvements in any area in order to guarantee an exchange of ideas and points of view.</p>
            </Grid>
            <Grid item xs={12} md={4} className='image-container'>
                <img src={DeskImg} alt='project management board'></img>
            </Grid>
        </Grid>
    )
}

function FourthBlock() {
    const classes = useStyles();

    return (
        <Grid container id='ourCulture-section' className={classes.fourthSection} direction='row' justify='space-between'>
            <Grid item xs={12} md={4} className='image-container'>
                <img src={BooksImg} alt='books'></img>
            </Grid>
            <Grid item xs={12} md={6} className='text-container'>
                <h1>Our culture</h1>
                <p>We work and study every day to improve.</p>
                <p>We believe in culture and sport as ways for developing new ideas, being creative and making better decisions</p>
            </Grid>
        </Grid>
    )
}