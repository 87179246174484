import { createMuiTheme } from '@material-ui/core/styles';

/// #TODO 
export const theme = createMuiTheme({
  typography: {
    fontFamily: [ '-apple-system', 'BlinkMacSystemFont', 'Ubuntu', 'sans-serif' ],
    fontWeightRegular: '500',
    fontSize: 20
  },
  palette: {
    primary: {
      main: '#00A2FF',
      dark: '#153C6B',
      contrastText: '#fff',
    },
    secondary: {
      main: '#153C6B',
      contrastText: '#fff',
    },
    info: {
      main: '#4fc16d',
      contrastText: '#fff',
    }
  },
});